import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';

import { ModalDispatchContext } from '../../../../../context/modalContext';
import { DeviceTypes } from '../../../../../utils/getDeviceType';
import MaxWidth from '../../../../shared/MaxWidth';
import { AnimatedMentalState } from './AnimatedMentalState';
import FeaturedIn from './components/FeaturedIn';
import * as S from './Hero.styles';

const ButtonsBlock = dynamic(() => import('./components/ButtonsBlock'), {
  ssr: false,
});

export const Hero = ({ animatedMentalStateVariant }: { deviceType: DeviceTypes; animatedMentalStateVariant?: 'A' | 'B' }) => {
  useOpenVideoModalWithMatchinQueryParam();

  return (
    <S.Wrapper>
      <S.Background />
      <S.DesktopImageBlock>
        <Image
          alt=""
          height={1200}
          priority
          src="https://cdn.brain.fm/images/test20241203/background.webp"
          width={2400}
        />
      </S.DesktopImageBlock>
      <S.Content>
        <MaxWidth>
          <S.InfoBlock>
            { animatedMentalStateVariant ? (
              <S.AnimatedMentalStateWrapper>
                <AnimatedMentalState variant={animatedMentalStateVariant} />
              </S.AnimatedMentalStateWrapper>
              ) : 
              <S.Caption>Focus mode for any task.</S.Caption>
            }
            {!animatedMentalStateVariant ? (
              <S.ActivitiesImage alt="" src="https://cdn.brain.fm/images/marketing_test_12_2024/activities.webp" width="100%"/>
            ) : null}
            <S.HeroText>The only music app made with modes for different activities in your life.</S.HeroText>
            <ButtonsBlock />
            <S.FeaturedContainer>
              <FeaturedIn />
            </S.FeaturedContainer>
          </S.InfoBlock>
        </MaxWidth>
      </S.Content>
      <S.MobileImageBlock>
        <S.FocusAura
          alt="Mobile Aura"
          height={800}
          priority={true}
          src="https://cdn.brain.fm/images/marketing/mobile_hero_aura.webp"
          width={800}
        />
        <Image
          alt="Mobile Hero"
          height={707}
          priority
          src="https://cdn.brain.fm/images/marketing/mobile_hero.webp"
          style={{ zIndex: 1 }}
          width={638}
        />
      </S.MobileImageBlock>
    </S.Wrapper>
  );
};

function useOpenVideoModalWithMatchinQueryParam() {
  const router = useRouter();
  const setModalState = useContext(ModalDispatchContext);

  useEffect(() => {
    if (router.query.showVideo === 'true') {
      setModalState({ howItWorksVideoVisible: true });
    }
  }, [router.query.showVideo]);
}
