import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 1rem 0;
`;

const Text = styled.p`
  text-transform: uppercase;
  font-size: 0.65rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
  letter-spacing: 0.15rem;
  text-align: left;
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
`;

export const FeaturedIn = () => {
  return (
    <Container>
      <Text>featured in</Text>
      <Image alt='Featured in' src='https://cdn.brain.fm/images/marketing/featured_in.png' />
    </Container>
  );
};

export default FeaturedIn;
